import Vue from 'vue';

/**
 * @class EventBus
 * 用于管理应用全局参数的事件总线
 */
const EventBus = new Vue({
  data: {
    
  }
})

export default EventBus;