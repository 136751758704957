var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{style:({
    '--primary-color': _vm.theme.primary,
    '--primary-color-rgb': _vm.theme.primaryRGB,
    '--secondary-color': _vm.theme.secondary,
    '--secondary-color-rgb': _vm.theme.secondaryRGB,
    '--accent-color': _vm.theme.accent,
    '--accent-color-rgb': _vm.theme.accentRGB,
    '--split-color': _vm.theme.split,
    '--split-color-rgb': _vm.theme.splitRGB,
    '--deep-background-color': _vm.theme.deepBackground,
    '--deep-background-color-rgb': _vm.theme.deepBackgroundRGB,
    '--light-background-color': _vm.theme.lightBackground,
    '--light-background-color-rgb': _vm.theme.lightBackgroundRGB,
  }),attrs:{"id":"app","data-app":""}},[_c('background-manager-component'),_c('router-view',{attrs:{"id":"main-router"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }